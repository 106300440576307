import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

const cardData = [
  { title: "orders", value: "4" },
  { title: "Total Pickup order", value: "10" },
  { title: "Total Pending order ", value: "6"}, 
];

function LaundryCards() {
  return (
    <Grid container spacing={6}>
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              height: { xl: "180px", lg: "180px" },
              width: { xl: "320px", lg: "320px" },
              marginBottom: "8px", // Adds a 4px gap between cards vertically
            }}
          >
            <CardContent>
              
              <Typography variant="subtitle1">{card.title}</Typography>
              <Typography variant="h5">{card.value}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default LaundryCards;
