import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import LaundryCamera from "./LaundryCamera";
import axios from "axios";

function LaundryDialog({ user, onClose }) {
  const [capturedImages, setCapturedImages] = useState({});
  const [cameraOpen, setCameraOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);

  const handleOpenCamera = (itemId) => {
    setCurrentItemId(itemId);
    setCameraOpen(true);
  };

  const handleCloseCamera = () => {
    setCameraOpen(false);
    setCurrentItemId(null);
  };

  const handleImageCapture = (imageSrc) => {
    setCapturedImages((prev) => ({
      ...prev,
      [currentItemId]: imageSrc,
    }));
    handleCloseCamera();
  };

  const handleSubmit = async () => {
    for (const itemId in capturedImages) {
      const formData = new FormData();

      // Convert base64 to Blob
      const base64Image = capturedImages[itemId].split(",")[1]; // Remove the data URL prefix
      const byteCharacters = atob(base64Image);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const imageBlob = new Blob([byteArray], { type: "image/jpeg" });

      // Append fields to FormData

      formData.append("image", imageBlob, `captured_image_${itemId}.jpg`);
      formData.append("user_id", user.user_id);
      formData.append("item_id", itemId);

      // Debug: Log FormData contents
      console.log(`Submitting data for item ${itemId}`, {
        user_id: user.user_id,
        item_id: itemId,
        image: imageBlob,
      });

      try {
        // Make a PUT request to the specified API
        const response = await axios.put(
          "https://fi26pmpfb5.execute-api.ap-south-1.amazonaws.com/dev/v1/updateItemDetails",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert(`Successfully updated item ${itemId}`);

        // Close the dialog after successful update
        onClose();
        // window.location.reload(); // Reload the page
      } catch (error) {
        console.error(`Error updating item ${itemId}:`, error);
        alert(`Failed to update item ${itemId}`);
      }
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {user.user_name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table aria-label="item details table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Item Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Clothes Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Clothes Piece</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Capture Image</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("user.order_detail:======>>>", user.order_detail)}
              {user.order_detail.map((item, index) => (
                <TableRow key={item.item_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.type_of_clothes}</TableCell>
                  <TableCell>{item.item_piece}</TableCell>
                  <TableCell>
                    {item.image ? (
                      <img src={item.image} alt="Captured" width="50" />
                    ) : capturedImages[item.item_id] ? (
                      <img
                        src={capturedImages[item.item_id]}
                        alt="Captured"
                        width="50"
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenCamera(item.item_id)}
                      >
                        Capture
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.status === "picked"
                      ? item.status
                      : capturedImages[item.item_id]
                      ? "picked"
                      : item.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
      </DialogContent>

      {cameraOpen && (
        <LaundryCamera
          open={cameraOpen}
          onClose={handleCloseCamera}
          onCapture={handleImageCapture}
        />
      )}
    </Dialog>
  );
}

export default LaundryDialog;
