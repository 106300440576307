import React, { useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { Dialog, DialogTitle, DialogContent, Button, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";

const videoConstraints = {
  // facingMode: "user",// Try to use the front camera
  facingMode: { exact: "environment" }, // Try to use the back camera
};

const LaundryCamera = ({ open, onClose, onCapture }) => {
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc); // Pass captured image to LaundryDialog
  }, [webcamRef, onCapture]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Capture Image
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={ovalStyle}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            style={videoStyle}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={capture}
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Capture Photo
        </Button>
      </DialogContent>
    </Dialog>
  );
};

// Styles
const ovalStyle = {
  position: "relative",
  width: "100%",
  height: "300px",
  borderRadius: "15px",
  overflow: "hidden",
};

const videoStyle = {
  width: "100%",
  height: "100%",
};

export default LaundryCamera;
