import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import LaundryCards from "../Cards/LaundryCards";
import LaundryTable from "../ExamList/LaundryTable";
import LaundryDialog from "../ExamList/LaundryDialog";

const Laundry = () => {
  const [selectedUser, setSelectedUser] = useState(null);

  const handleViewClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
  };

  return (
    <Box
      component="main"
      sx={{ marginTop: "60px", width: { xs: "100%", sm: "100%" } }}
    >
      <Stack>
        <LaundryCards />
      </Stack>

      <Stack sx={{ marginTop: "12px" }}>
        <LaundryTable onViewClick={handleViewClick} />
        {selectedUser && (
          <LaundryDialog user={selectedUser} onClose={handleCloseDialog} />
        )}
      </Stack>
    </Box>
  );
};

export default Laundry;
