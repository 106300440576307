import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";

function LaundryTable({ onViewClick }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fi26pmpfb5.execute-api.ap-south-1.amazonaws.com/dev/v1/fetchAllOrders"
        );
        // Add dummy location to each row if location is missing
        const updatedRows = response.data.map((row) => ({
          ...row,
          location: row.location || "22.7196,75.8577", // Dummy location (Indore, MP)
        }));
        setRows(updatedRows); // Set the fetched data to the rows state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Open Google Maps for the given location
  const handleLocationClick = (location) => {
    const [lat, lng] = location.split(",");
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank"); // Open in a new tab
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: { xs: "100%", sm: "auto" },
        overflowX: "auto", // Enable horizontal scrolling on smaller screens
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: "bold", padding: 2 }}>
        Laundry Order List
      </Typography>
      <Table aria-label="exam table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>S.No.</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>User Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Mobile No.</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Items Quantity</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Items Weight</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.user_name}</TableCell>
              <TableCell>{row.user_address}</TableCell>
              <TableCell>{row.mobile_no}</TableCell>
              <TableCell>{row.order_detail.length}</TableCell>
              <TableCell>{row.total_weight}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleLocationClick(row.location)}
                >
                  Track Location
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onViewClick(row)}
                >
                  View Items
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LaundryTable;
